@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"),
    url("./Components/assets/fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-style: normal;
  font-weight: normal;

  src: local("Gilroy-Heavy"),
    url("./Components/assets/fonts/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light"),
    url("./Components/assets/fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"),
    url("./Components/assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular"),
    url("./Components/assets/fonts/Gilroy-Regular.woff") format("woff");
}

/* Popup style */
.popup-box {
  position: fixed;
  /* border: 2px solid red; */
  background: #00000050;
  width: 100%;
  height: 100vh;

  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 40%;
  margin: auto;
  /* color: aqua; */
  height: auto;
  max-height: 98vh;
  /* margin-bottom: 150% !important; */
  /* margin-left: auto; */
  margin-top: calc(14vh -75px) !important;
  background: #fff;
  border-radius: 20px;
  /* padding: 12px 40px; */
  border: 1px solid #999;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.box::-webkit-scrollbar {
  display: none;
}
.close-icon {
  content: "x";
  cursor: pointer;
  /* text-align: right; */
  margin-right: 100px;
  /* position: relative; */
  /* right: calc(15% - 30px); */
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 20px;

  border: 5px solid #999;
  font-size: 50px;
}

.first_button:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  transition: 0.5s;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* color: #cc0000; */
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
body {
  margin: 0;
  font-size: 25px;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  display: block;
  height: 100px;
  width: 160px;
}
.logo-mobile {
  display: none;
}
.logo-mobile2 {
  display: inline;
}

img:hover {
  cursor: pointer;
}

.thankyou {
  margin-top: 40px;
  width: 94px !important;
  margin-left: 130px;
}
.headings-thankyou {
  font-family: "Gilroy-Heavy";
  font-size: 36px;
  line-height: 40px;
  /* font-weight: bold; */
  color: #0765f6;
}

.headings-thankyou1 {
  font-family: "Gilroy-Heavy";
  font-size: 35px;
  line-height: 30px;
  /* font-weight: bold; */
  color: #0765f6;
}
.button {
  color: white;
  border: none;
  margin: 10px 0px 10px 0px;
  background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
  outline: none;
  padding: 10px 30px 10px 30px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Poppins";
}
.hiring-process-cards {
  padding: 15px;
  margin: 10px;
  border-radius: 25px;
  border: 1px solid #0765f6;
  font-family: "Poppins";
  font-weight: 600;
}
.hiring-process-cards:hover {
  cursor: pointer;
  background-color: #0765f6;
  color: white;
}
.contact-form {
  padding: 0px 25px;
}
.contact-input-field {
  margin-bottom: 15px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  width: 100%;
}
.footer .contact-input-field {
  margin-bottom: 15px;
  outline: none;
  color: white;
  border: 1px solid gainsboro;
  border-radius: 5px;
  background-color: #0765f6;
  padding: 10px;
  width: 100%;
}
.footer .contact-input-field::placeholder {
  color: #fff;
}

.form-button {
  color: white;
  border: none;
  width: 100%;
  margin: auto;
  background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
  outline: none;
  padding: 15px 0px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Poppins";
}

.form-button1 {
  color: white;
  border: none;
  width: 100%;
  margin-left: -59px !important;
  background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
  outline: none;
  padding: 15px 0px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Poppins";
}

.footer-form-button {
  color: #0719f6;
  border: none;
  outline: none;
  margin: auto;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Poppins";
}

.about-us-img {
  width: 100%;
  height: 100%;
}
.icon-imgs {
  height: 100%;
  width: 100%;
}
.navbar-item {
  font-weight: bold;
  margin: 20px;
}
.box1 {
  height: 180px;
  width: 200px;
  border-right: 3px solid #6c00ff;
  border-bottom: 3px solid #6c00ff;
  border-top: 3px solid #6c00ff;
  position: relative;
  margin: auto;
}
.box1:before {
  content: "";
  height: 50px;
  width: 3px;
  background: #6c00ff;
  bottom: 0px;
  position: absolute;
}
.box2 {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.intro-img {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
}
.intro-img-mobile {
  display: none;
}
.intro {
  display: block;
}
.mobile-view {
  display: none;
  background: url(./Components/assets/images/mobile_responsive/top_image_OhbRvE.jpg)
    no-repeat center center;
}
.mobile-view1 {
  display: none;
}

.headings {
  font-family: "Gilroy-Heavy";
}

.headings-hiring {
  font-family: "Gilroy-Heavy";
}
.headings-hiring-mob {
  font-family: "Gilroy-Heavy";
  display: none;
}
.sub-headings {
  font-family: "Poppins";
  font-weight: 600;
}
.subheadings-thankyou {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
}

.tech-list {
  font-size: larger;
  color: black;
}
.tech-list span {
  cursor: pointer;
}
.tech-list .selected {
  font-weight: bold;
  color: #0765f6;
}
.box-content {
  margin-left: -15%;
}
.box-content h1 {
  font-size: xxx-large;
  font-weight: bold;
  color: #0765f6;
}
.box-content h4 {
  font-size: x-large;
  font-weight: bold;
}
.footer {
  color: white;
}
.banner-container {
  position: relative;
  width: 100%;
}
.mobile-banner {
  display: none;
}

.active-dot {
  color: #0765f6;
}
.banner-container .btn {
  position: absolute;
  top: 50%;
  max-width: 300px;
  right: 0%;
  outline: none;
  transform: translate(-20%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  color: #0765f6;
  font-size: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
}
.error-msg {
  margin-top: -15px;
  font-size: 12px;
  color: red;
  text-align: left;
}
.footer .error-msg {
  font-size: 12px;
  color: white;
  text-align: left;
}
.steps-img-heads {
  font-weight: bold;
}
.steps-img-heads:hover {
  transform: scale(1.3);
  cursor: pointer;
  color: #0765f6;
}
.slider-img:hover {
  cursor: pointer;
}
.testimonial-card {
  background: url(./Components/assets/images/Testimonial\ Card.png) no-repeat
    center center;
  height: 500px;
  background-size: 90% 95%;
  padding: 8% 15%;
  position: relative;
}
.testimonial-card-footer {
  position: absolute;
  width: 85%;
  padding: 10px 20px;
  margin-left: -8%;
  text-align: center;
  top: 380px;
  color: #fff;
}
.testimonial-card:hover {
  transform: scale(1.02);
}
.para {
  text-align: justify;
  line-height: 1.6;
}

.iframe-container {
  position: relative;
}
.iframe-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.iframe-container iframe {
  position: absolute;
  width: 74%;
  left: 13.5%;
  top: 2%;
  height: 87%;
  cursor: pointer;
}
.iframe-container iframe:hover {
  cursor: pointer;
}
.reasons-card {
  cursor: pointer;
}
.reasons-card:hover h4,
.reasons-card.hover h4 {
  color: #0719f6;
  font-weight: bold;
}
.hiring-model-card {
  cursor: pointer;
  border-radius: 15px;
}
.hiring-model-card:hover {
  transform: scale(1.03);
}
.hiring-model-card:hover h5,
.hiring-model-card.hover h5 {
  color: #0719f6;
  font-weight: bold;
}
.cr {
  border-radius: 20px;
}
.desktop-view {
  display: block;
}
.mobile-view {
  display: none;
}
.navbar #hidden {
  position: fixed;
  top: 0%;
  right: 0%;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  overflow-x: hidden;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

.navbar #hidden span {
  margin-left: 5%;
  color: #000;
}

/* @media screen and (device-width: 360px) and (device-height: 640px) {
  .intro-img {
    position: absolute;
    opacity: 50%;
    right: 0px;
    top: 0px;
    z-index: -1;
  }
  .steps-img-heads {
    font-size: 10px;
    font-weight: bold;
  }
  .steps-img-heads:hover {
    transform: scale(1.3);
    cursor: pointer;
    color: #0765f6;
  }
  .contact-form {
    padding: 0px 0px;
  }
  .contact-input-field {
    margin-bottom: 15px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    width: 100%;
  }
  .hiring-process-cards {
    padding: 15px;
    margin: 15px 0px;
    border-radius: 25px;
    border: 1px solid #0765f6;
    font-family: "Poppins";
    font-weight: 600;
  }
  .logo {
    height: 80px;
    width: 130px;
  }
  .button {
    color: white;
    border: none;
    margin: 10px 0px 10px 0px;
    background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
    outline: none;
    padding: 10px 30px 10px 30px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 1.2rem;
    font-family: "Poppins";
  }

  .testimonial-card {
    background: url(./Components/assets/images/Testimonial\ Card.png) no-repeat
      center center;
    height: 500px;
    margin-left: -30px;
    margin-right: -10px;
    background-size: 100% 100%;
    padding: 10% 10%;
    position: relative;
  }
  .testimonial-card-footer {
    position: absolute;
    width: 95%;
    top: 400px;
    color: #fff;
  }
  .testimonial-card:hover {
    transform: scale(1.02);
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .banner-container {
    position: relative;
    width: 100%;
  }
  .banner-img {
    display: none;
  }
  .mobile-banner {
    display: block;
  }
  .banner-container .btn {
    display: none;
  }
  .headings-hiring {
    display: none;
    font-family: "Gilroy-Heavy";
  }
  .headings-hiring-mob {
    display: block;
    font-family: "Gilroy-Heavy";
  }
} */

@media screen and (max-width: 460px) {
  .intro-img {
    display: none;
    position: absolute;
    opacity: 50%;
    right: 0px;
    top: 0px;
    z-index: -1;
  }
  .intro-img-mobile {
    display: block;
    position: absolute;
    /* opacity: 50%; */
    right: 0px;
    margin-top: -20px;
    /* top: 0px; */
    z-index: -1;
  }
  .steps-img-heads {
    font-size: 10px;
    font-weight: bold;
  }
  .steps-img-heads:hover {
    transform: scale(1.3);
    cursor: pointer;
    color: #0765f6;
  }
  .contact-form {
    padding: 0px 0px;
  }
  .contact-input-field {
    margin-bottom: 15px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    width: 100%;
  }
  .hiring-process-cards {
    padding: 15px;
    margin: 15px 0px;
    border-radius: 25px;
    border: 1px solid #0765f6;
    font-family: "Poppins";
    font-weight: 600;
  }
  .logo {
    height: 80px;
    display: none;
    width: 130px;
  }
  .logo-mobile {
    /* border: 2px solid red; */
    display: inline;
  }
  .logo-mobile2 {
    display: inline;
  }

  .button {
    color: white;
    border: none;
    margin: 10px 0px 10px 0px;
    background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
    outline: none;
    padding: 10px 30px 10px 30px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 1.2rem;
    font-family: "Poppins";
  }

  .testimonial-card {
    background: url(./Components/assets/images/Testimonial\ Card.png) no-repeat
      center center;
    height: 500px;
    margin-left: -30px;
    margin-right: -10px;
    background-size: 100% 100%;
    padding: 10% 10%;
    position: relative;
  }
  .testimonial-card-footer {
    position: absolute;
    width: 95%;
    top: 400px;
    color: #fff;
  }
  .testimonial-card:hover {
    transform: scale(1.02);
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .banner-container {
    position: relative;
    width: 100%;
  }
  .banner-img {
    display: none;
  }
  .mobile-banner {
    display: block;
    margin-left: -5%;
    margin-right: -5%;
  }
  .banner-container .btn {
    display: none;
  }
  .headings-hiring {
    display: none;
    font-family: "Gilroy-Heavy";
  }
  .headings-hiring-mob {
    display: block;
    font-family: "Gilroy-Heavy";
  }
  .intro {
    display: none;
  }
  .mobile-view {
    display: block;
    background: url(./Components/assets/images/mobile_responsive/top_image_OhbRvE.jpg)
      no-repeat center center;
  }

  .headings-thankyou {
    font-family: "Gilroy-Heavy";
    font-size: 30px;
    line-height: 40px;
    /* font-weight: bold; */
    color: #0765f6;
  }
  .headings-thankyou1 {
    font-family: "Gilroy-Heavy";
    font-size: 30px;
    line-height: 10px;
    /* font-weight: bold; */
    color: #0765f6;
  }

  .subheadings-thankyou {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 1rem;
    margin-left: -4px !important;
    line-height: 20px;
  }
  .thankyou {
    margin-top: 30px;
    width: 100px !important;
    margin-left: 72px;
  }
  .box {
    position: relative;
    width: 90%;

    margin: auto;
    height: auto;
    max-height: 98vh;
    /* margin-left: auto; */
    margin-top: calc(100vh - 95vh - 20px);
    background: #fff;
    border-radius: 8px;
    padding: 25px 40px;
    border: 1px solid #999;
    overflow: auto;
  }
  .form-button1 {
    color: white;
    border: none;
    width: 100%;
    margin-left: -6px !important;
    margin: auto;
    background-image: linear-gradient(to right, #6c00ff, #0719f6, #0765f6);
    outline: none;
    padding: 15px 0px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Poppins";
  }
  .unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* color: #cc0000; */
  }
  .mobile-view1 {
    display: block;
  }
}
